<template>
  <div :class="{'loading': !loaded}">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmMovementType')" data-vv-scope="frmMovementType" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.movementType') }}</h5>
            <div class="row">
                <custom-input
                  name="movementType.name"
                  :label="this.$i18n.t('form.movementType.description')"
                  v-model="movementType.name"
                  type="text"
                  rootClassName="col-lg-9 form-group mb-8"
                  inputClassName="md-form-control"
                  :max-length="25"
                  :error="submitted = errors.first('movementType.name')">
                </custom-input>
                <div class="col-lg-3 form-group mb-4">
                  <custom-switch
                    :listItems="[{text: 'Optante Simples Nacional', value: true}]"
                    v-model="movementType.is_simples"
                    name="movementType.is_simples"
                    type="checkbox"
                    rootClassName="col-lg-12 text-left"
                    stateClassName="p-primary"
                    :error="errors.first('movementType.is_simples')">
                  </custom-switch>
                  <br />
                  <custom-switch
                    :listItems="[{text: 'Incentivador Cultural', value: true}]"
                    v-model="movementType.is_cultural"
                    name="movementType.is_cultural"
                    type="checkbox"
                    rootClassName="col-lg-12"
                    stateClassName="p-primary"
                    :error="errors.first('movementType.is_cultural')">
                  </custom-switch>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.descriptionInvoice') }}</h5>
            <fieldset>
              <label>{{ $t('form.movementType.buttonInsertField') }}</label>
              <v-select :options="columnsBill" @input="(value) => onSelectColumnBill(value, 'movementTypeDesc')">
                <template #option="{ label }">
                  {{ $t(`form.movementType.variableField.${label}`) }}
                </template>
              </v-select>
              <custom-textarea
                v-model="movementType.description"
                name="movementTypeDesc"
                type="text"
                rootClassName="md-form mb-0"
                inputClassName="md-form-control"
                :rows="7"
                ref="movementTypeDesc"
                v-validate="{ required: false }"
                background="#fff"
                :error="errors.first('movementType.description')">
              </custom-textarea>
              <span class="text-helper">Essa descrição deverá repetir em caso de faturas agrupadas.</span>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.descriptionAirInvoice') }}</h5>
            <fieldset>
              <label>{{ $t('form.movementType.buttonInsertField') }}</label>
              <v-select :options="columnsBill" @input="(value) => onSelectColumnBill(value, 'movementTypeDescAir')">
                <template #option="{ label }">
                  {{ $t(`form.movementType.variableField.${label}`) }}
                </template>
              </v-select>
              <custom-textarea
                v-model="movementType.description_air"
                name="movementTypeDescAir"
                type="text"
                rootClassName="md-form mb-0"
                inputClassName="md-form-control"
                :rows="7"
                ref="movementTypeDescAir"
                v-validate="{ required: false }"
                background="#fff"
                :error="errors.first('movementType.description_air')">
              </custom-textarea>
              <span class="text-helper">Essa descrição deverá repetir em caso de faturas agrupadas.</span>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.descriptionFixedInvoice') }}</h5>
            <fieldset>
              <label>{{ $t('form.movementType.buttonInsertField') }}</label>
              <v-select :options="columnsBill" @input="(value) => onSelectColumnBill(value, 'movementTypeDescFixed')">
                <template #option="{ label }">
                  {{ $t(`form.movementType.variableField.${label}`) }}
                </template>
              </v-select>
              <custom-textarea
                v-model="movementType.description_fixed"
                name="movementTypeDescFixed"
                type="text"
                rootClassName="md-form mb-0"
                inputClassName="md-form-control"
                :rows="7"
                ref="movementTypeDescFixed"
                v-validate="{ required: false }"
                background="#fff"
                :error="errors.first('movementType.description_fixed')">
              </custom-textarea>
              <span class="text-helper">Essa descrição será única.</span>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.id_servico') }}</h5>
            <br />
            <v-select
              label="nome"
              class="md-form-control"
              :clearable="true"
              :searchable="true"
              v-model="movementType.id_servico"
              :options="servicesList"
              @input="onSelectService">
              <template slot="no-options">
                {{ 'Nenhum resultado encontrado' }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.id_natureza_operacao') }}</h5>
            <br />
            <v-select
              label="nome"
              class="md-form-control"
              :clearable="true"
              :searchable="true"
              v-model="movementType.id_natureza_operacao"
              :options="operationNatureList"
              @input="onSelectOperationNature">
              <template slot="no-options">
                {{ 'Nenhum resultado encontrado' }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.id_regime_tributario') }}</h5>
            <br />
            <v-select
              label="nome"
              class="md-form-control"
              v-model="movementType.id_regime_tributario"
              :options="taxRegimeList"
              @input="onSelectTaxRegime"  >
              <template slot="no-options">
                {{ 'Nenhum resultado encontrado' }}
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.codigo }} - {{ option.nome }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.component') }}</h5>
            <br />
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <custom-input
                    name="movementType.calculation_basis"
                    :label="this.$i18n.t('form.movementType.calculationBasis')"
                    type="number"
                    v-model="movementType.calculation_basis"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.calculation_basis')">
                  </custom-input>

                <custom-input
                    name="movementType.ISSaliquot"
                    :label="this.$i18n.t('form.movementType.ISSaliquot')"
                    type="number"
                    v-model="movementType.issaliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.ISSaliquot')">
                  </custom-input>

                <custom-input
                    name="movementType.deductionAmount"
                    :label="this.$i18n.t('form.movementType.deductionAmount')"
                    type="number"
                    v-model="movementType.deduction_amount"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.deductionAmount')">
                  </custom-input>

                  <custom-input
                    name="movementType.PISCalculationBase"
                    :label="this.$i18n.t('form.movementType.PISCalculationBase')"
                    type="number"
                    v-model="movementType.piscalculationbase"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.PISCalculationBase')">
                  </custom-input>

                  <custom-input
                    name="movementType.COFINSCalculationBase"
                    :label="this.$i18n.t('form.movementType.COFINSCalculationBase')"
                    type="number"
                    v-model="movementType.cofinscalculationbase"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.COFINSCalculationBase')">
                  </custom-input>

                  <custom-input
                    name="movementType.CSLLCalculationBase"
                    :label="this.$i18n.t('form.movementType.CSLLCalculationBase')"
                    type="number"
                    v-model="movementType.csllcalculationbase"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.CSLLCalculationBase')">
                  </custom-input>

                  <custom-input
                    name="movementType.INSSCalculationBase"
                    :label="this.$i18n.t('form.movementType.INSSCalculationBase')"
                    type="number"
                    v-model="movementType.insscalculationbase"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.INSSCalculationBase')">
                  </custom-input>

                  <custom-input
                    name="movementType.IRCalculationBase"
                    :label="this.$i18n.t('form.movementType.IRCalculationBase')"
                    type="number"
                    v-model="movementType.ircalculationbase"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.IRCalculationBase')">
                  </custom-input>

                  <custom-input
                    name="movementType.otherWithholdings"
                    :label="this.$i18n.t('form.movementType.otherWithholdings')"
                    type="number"
                    v-model="movementType.otherwithholdings"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.otherWithholdings')">
                  </custom-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <custom-input
                    name="movementType.unconditionalDiscount"
                    :label="this.$i18n.t('form.movementType.unconditionalDiscount')"
                    type="number"
                    v-model="movementType.unconditional_discount"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.unconditionalDiscount')">
                  </custom-input>

                <custom-input
                    name="movementType.ISSvalueWithheld"
                    :label="this.$i18n.t('form.movementType.ISSvalueWithheld')"
                    type="number"
                    v-model="movementType.issvaluewithheld"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.ISSvalueWithheld')">
                  </custom-input>

                <custom-input
                    name="movementType.ISSWithheld"
                    :label="this.$i18n.t('form.movementType.ISSWithheld')"
                    type="number"
                    v-model="movementType.isswithheld"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.ISSWithheld')">
                  </custom-input>

                  <custom-input
                    name="movementType.PISAliquot"
                    :label="this.$i18n.t('form.movementType.PISAliquot')"
                    type="number"
                    v-model="movementType.pisaliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.PISAliquot')">
                  </custom-input>

                  <custom-input
                    name="movementType.COFINSAliquot"
                    :label="this.$i18n.t('form.movementType.COFINSAliquot')"
                    type="number"
                    v-model="movementType.cofinsaliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.COFINSAliquot')">
                  </custom-input>

                  <custom-input
                    name="movementType.CSLLAliquot"
                    :label="this.$i18n.t('form.movementType.CSLLAliquot')"
                    type="number"
                    v-model="movementType.csllaliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.CSLLAliquot')">
                  </custom-input>

                  <custom-input
                    name="movementType.INSSAliquot"
                    :label="this.$i18n.t('form.movementType.INSSAliquot')"
                    type="number"
                    v-model="movementType.inssaliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.INSSAliquot')">
                  </custom-input>

                  <custom-input
                    name="movementType.IRAliquot"
                    :label="this.$i18n.t('form.movementType.IRAliquot')"
                    type="number"
                    v-model="movementType.iraliquot"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.IRAliquot')">
                  </custom-input>

                  <custom-input
                    name="movementType.totalRetention"
                    :label="this.$i18n.t('form.movementType.totalRetention')"
                    type="number"
                    v-model="movementType.total_retention"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.totalRetention')">
                  </custom-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <custom-input
                    name="movementType.conditionalDiscount"
                    :label="this.$i18n.t('form.movementType.conditionalDiscount')"
                    type="number"
                    v-model="movementType.conditional_discount"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.conditionalDiscount')">
                  </custom-input>

                <custom-input
                    name="movementType.ISSValue"
                    :label="this.$i18n.t('form.movementType.ISSValue')"
                    type="number"
                    v-model="movementType.issvalue"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.ISSValue')">
                  </custom-input>

                <custom-input
                    name="movementType.ISSAmountRetained"
                    :label="this.$i18n.t('form.movementType.ISSAmountRetained')"
                    type="number"
                    v-model="movementType.issamountretained"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.ISSAmountRetained')">
                  </custom-input>

                  <custom-input
                    name="movementType.PISValue"
                    :label="this.$i18n.t('form.movementType.PISValue')"
                    type="number"
                    v-model="movementType.pisvalue"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.PISValue')">
                  </custom-input>

                  <custom-input
                    name="movementType.COFINSValue"
                    :label="this.$i18n.t('form.movementType.COFINSValue')"
                    type="number"
                    v-model="movementType.cofinsvalue"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.COFINSValue')">
                  </custom-input>

                  <custom-input
                    name="movementType.CSLLAmount"
                    :label="this.$i18n.t('form.movementType.CSLLAmount')"
                    type="number"
                    v-model="movementType.csllamount"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.CSLLAmount')">
                  </custom-input>

                  <custom-input
                    name="movementType.INSSAmount"
                    :label="this.$i18n.t('form.movementType.INSSAmount')"
                    type="number"
                    v-model="movementType.inssamount"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.INSSAmount')">
                  </custom-input>

                  <custom-input
                    name="movementType.IRValue"
                    :label="this.$i18n.t('form.movementType.IRValue')"
                    type="number"
                    v-model="movementType.irvalue"
                    rootClassName="form-group"
                    inputClassName="md-form-control"
                    :max-length="25"
                    :error="submitted = errors.first('movementType.IRValue')">
                  </custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.movementType.taxes') }}</h5>
            <br />
            <multiselect
              v-model="movementType.taxes"
              :multiple="true"
              :max="1000"
              track-by="id"
              label="name"
              :allow-empty="true"
              placeholder="Selecione uma ou mais taxas para o tipo de movimentação"
              :close-on-select="false"
              :clear-on-select="false"
              :custom-label="customLabel"
              :options="taxList"
              :show-labels="false">
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.id }}</strong> - <strong>{{ option.nome }}</strong>
              </template>
              <template slot="option" slot-scope="{ option }">
                <strong>{{ option.id }}</strong> - <strong>{{ option.nome }}</strong>
              </template>
            </multiselect>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveMovementType">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelMovementType">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import TaxLogisticService from '@/services/TaxLogisticService'
import TaxRegimeService from '@/services/TaxRegimeService'
import OperationNatureService from '@/services/OperationNatureService'
import ServiceService from '@/services/ServiceService'
import MovementTypeService from '@/services/MovementTypeService'
export default {
  name: 'MovementTypeForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.movementTypes.title') + ' - %s'
    }
  },
  data () {
    return {
      loaded: true,
      isEdit: false,
      formErrors: {},
      movementType: {
        description: '',
        description_fixed: '',
        name: null,
        type: 2
      },
      taxList: [],
      taxRegimeList: [],
      servicesList: [],
      operationNatureList: [],
      isLoading: true,
      fullPage: true,
      submitted: false,
      columnsBill: [
        // 'billNumber',
        // 'serie',
        'numberProcess',
        'referenceTaker',
        'numberHBL',
        'totalAmount',
        'emissionDateTime',
        'competence',
        'takerId',
        'customerId',
        'takerType',
        'takerDoc',
        'takerName',
        'takerAddress',
        'takerZipCode',
        'takerMunicipalReg',
        'takerStateReg',
        'takerPhone',
        'takerDistrict',
        'takerCity',
        'takerState',
        'takerEmail',
        'servicosDesc',
        'servicoCode',
        'servicoDesc',
        'operationNatureCode',
        'operationNatureDesc',
        'placeCity',
        'placeState',
        'valueServices',
        'calculationBasis',
        'unconditionalDiscount',
        'conditionalDiscount',
        'otherWithholdings',
        'deductionsAmount',
        'netValue',
        'complementaryInfo',
        'otherInfo',
        'status'
      ],
      personDefaultList: [
        {
          value: '',
          name: 'Cliente'
        },
        {
          value: '',
          name: 'Exportador'
        },
        {
          value: '',
          name: 'Importador'
        },
        {
          value: '',
          name: 'Agente'
        },
        {
          value: '',
          name: 'Outros'
        }
      ]
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput,
    Multiselect,
    CustomSwitch,
    CustomTextarea
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    let movementTypeId = this.$route.params.type
    TaxLogisticService.getTaxs({}, 1, 1000).then(res => {
      this.taxList = res.data.data
    })
    TaxRegimeService.getTaxsRagime({}).then(res => {
      this.taxRegimeList = res.data.data
    })
    OperationNatureService.getOperationNatures({}).then(res => {
      this.operationNatureList = res.data.data
    })
    ServiceService.getServices({}).then(res => {
      this.servicesList = res.data.data
    })
    if (movementTypeId) {
      MovementTypeService.getMovementType(movementTypeId).then(res => {
        this.movementType = res.data.data
        this.isLoading = false
      })
    } else {
      this.isLoading = false
    }
  },
  methods: {
    onSelectColumnBill (value, input) {
      var textArea = document.getElementsByName(input)[0]
      var startPos = textArea.selectionStart
      // get cursor's position:
      var endPos = textArea.selectionEnd
      var cursorPos = startPos
      var tmpStr = textArea.value

      if (value === null) {
        return
      }

      // insert:
      if (input === 'movementTypeDescFixed') {
        this.movementType.description_fixed = (tmpStr.substring(0, startPos) + ` [${value}] ` + tmpStr.substring(endPos, tmpStr.length)).trim()
      } else if (input === 'movementTypeDescAir') {
        this.movementType.description_air = (tmpStr.substring(0, startPos) + ` [${value}] ` + tmpStr.substring(endPos, tmpStr.length)).trim()
      } else {
        this.movementType.description = (tmpStr.substring(0, startPos) + ` [${value}] ` + tmpStr.substring(endPos, tmpStr.length)).trim()
      }

      // move cursor:
      setTimeout(() => {
        cursorPos += value.length
        textArea.selectionStart = textArea.selectionEnd = cursorPos
      }, 10)
    },
    insertFieldDescription (field) {
    },
    taxRegimeFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TaxRegimeService.getTaxsRagime(filters).then(res => {
        me.taxRegimeList = res.data.data
        loading(false)
      })
    },
    onSelectTaxRegime (val) {
      this.movementType.id_regime_tributario = val
    },
    operationNatureFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      OperationNatureService.getOperationNatures(filters).then(res => {
        me.operationNatureList = res.data.data
        loading(false)
      })
    },
    onSelectOperationNature (val) {
      this.movementType.id_natureza_operacao = val
    },
    serviceFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ServiceService.getServices(filters).then(res => {
        me.servicesList = res.data.data
        loading(false)
      })
    },
    onSelectService (val) {
      this.movementType.id_servico = val
    },
    onCancelMovementType (e) {
      this.$router.push({ name: 'InvoiceMovementInternationalTypes' })
    },
    onSelectPersonDefault (val) {
      this.movementType.personDefault = val
    },
    onSaveMovementType (e) {
      let _this = this
      _this.isLoading = true
      this.submitted = true

      let movementTypeId = this.$route.params.type
      if (movementTypeId) {
        delete this.movementType.id_movement_type
        MovementTypeService.putMovementType(movementTypeId, this.movementType).then(response => {
          _this.$router.push({ name: 'InvoiceMovementInternationalTypes' })
        }).catch(() => {
          _this.isLoading = false
        })
      } else {
        delete this.movementType.id_movement_type
        MovementTypeService.postMovementType(this.movementType).then(response => {
          _this.$router.push({ name: 'InvoiceMovementInternationalTypes' })
        }).catch(() => {
          _this.isLoading = false
        })
      }
    },
    // eslint-disable-next-line camelcase
    customLabel ({ id, nome }) {
      // eslint-disable-next-line camelcase
      return `${id} – ${nome}`
    }
  }
}
</script>

<style>

</style>
